<template>
  <div class="uk-card uk-card-default kandang-card">
    <div class="uk-grid">
      <div class="uk-width-1-2">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Siklus Ke
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.cylce_now"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Mulai Persiapan
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.start_preparation"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Chick In
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.chick_in"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
      </div>
      <div class="uk-width-1-2">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Mulai Panen
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.start_harvest"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Chick Out
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.chick_out"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Sanitasi
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.sanitation"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="uk-grid">
      <div class="uk-width-1-2">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Tahapan
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.activity"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Populasi (ekor)
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.population"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Deplesi (ekor)
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.dead_chicken"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <!-- <div class="uk-grid uk-grid-small">
              <div class="uk-width-2-5">Bobot Rataan</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="data.average_weight"
                  type="text"
                  disabled
                >
              </div>
            </div> -->
      </div>
      <div class="uk-width-1-2">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Bobot Sampel (gr)
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="data.weight_sample"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-2-5">
            Stok Pakan (sak)
          </div>
          <div class="uk-width-3-5">
            <input
              v-model="food_stock"
              class="uk-input"
              type="text"
              disabled
            >
          </div>
        </div>
        <!-- <div class="uk-grid uk-grid-small">
              <div class="uk-width-2-5">Index Prestasi (IP)</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="data.ip"
                  type="text"
                  disabled
                >
              </div>
            </div> -->
      </div>
    </div>
    <TableSiklus :save-data="saveData" />
    <div class="uk-width-1-1 uk-text-right uk-margin-top">
      <button
        class="uk-button uk-button-danger"
        type="button"
        @click="goToBack"
      >
        Batal
      </button>
      <button
        class="uk-button uk-button-primary uk-margin-left"
        type="button"
        :disabled="editData"
        @click="showSaveConfirmModal"
      >
        <img
          v-lazy="`${images}/icon/save.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Simpan
      </button>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import TableSiklus from './TableSiklus'
import { float3Decimals, dateParanjeCompleteString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    TableSiklus
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      editCycle: [],
      data: {
        activity: null,
        population: 0,
        dead_chicken: 0,
        weight_sample: 0,
        cageDetail: null,
        average_weight: 0,
        ip: 0
      },
      food_stock: 0,
      editData: true
    }
  },
  computed: {
    ...mapGetters({
      cage: 'cage/cage'
    })
  },
  watch: {
    cage () {
      const dataCycle = this.cage.data

      const startPrepare = dateParanjeCompleteString(dataCycle.cycle.start_preparation)
      const startHarvest = dateParanjeCompleteString(dataCycle.cycle.start_harvest)
      const chickIn = dateParanjeCompleteString(dataCycle.cycle.chick_in)
      const chickOut = dateParanjeCompleteString(dataCycle.cycle.chick_out)
      const sanitation = dateParanjeCompleteString(dataCycle.cycle.sanitation)

      dataCycle.cycle.start_preparation = startPrepare !== '01 Januari 1' ? startPrepare : ''
      dataCycle.cycle.start_harvest = startHarvest !== '01 Januari 1' ? startHarvest : ''
      dataCycle.cycle.chick_in = chickIn !== '01 Januari 1' ? chickIn : ''
      dataCycle.cycle.chick_out = chickOut !== '01 Januari 1' ? chickOut : ''
      dataCycle.cycle.sanitation = sanitation !== '01 Januari 1' ? sanitation : ''
      const cycleNow = dataCycle.cycle_count ? dataCycle.cycle_count : 0

      this.data = {
        cylce_now: cycleNow,
        start_preparation: dataCycle.cycle.start_preparation,
        chick_in: dataCycle.cycle.chick_in,
        start_harvest: dataCycle.cycle.start_harvest,
        chick_out: dataCycle.cycle.chick_out,
        sanitation: dataCycle.cycle.sanitation,
        average_weight: dataCycle.average_weight ? dataCycle.average_weight : 0,
        ip: dataCycle.ip ? dataCycle.ip : 0,
        weight_sample: this.formatFloat(dataCycle.chick_body_weight) || 0,
        activity: dataCycle.current_activity_name || '-',
        population: dataCycle.chick_left || 0,
        dead_chicken: dataCycle.depletion || 0
      }
    }
  },
  methods: {
    ...mapMutations({
      setModalEdit: 'cycle/SET_MODAL_EDIT'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    formatFloat(number) {
      return float3Decimals(number)
    },
    saveData(data) {
      this.editData = false
      this.editCycle = data
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          window.UIkit.modal('#modal-save-confirm').show()
          this.setModalEdit(this.editCycle)
        }
      })
    }
  }
}
</script>

<style scoped>
.uk-button-default:disabled, .uk-button-primary:disabled, .uk-button-secondary:disabled, .uk-button-danger:disabled {
  background-color: #0000001f;
}
</style>
