<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Ubah Data Siklus
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container />
    <div id="container-modal-edit-cage" />
    <modal-out-confirm />
    <modal-save-confirm />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    BreadCrumb
  },
  data() {
    return {
      breadcrumb: [
        {
          link: '/admin/kandang-ayam',
          title: 'Daftar Kandang Ayam'
        },
        {
          link: null,
          title: 'Ubah Data Siklus'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      meta: 'cycle/meta'
    })
  },
  async mounted() {
    await this.getCageDetail(this.$route.params.id)
    await this.getCycleNoLimit({cage_id: this.$route.params.id, log_cycle_id: ''})
  },
  methods: {
    ...mapActions({
      getCycleNoLimit: 'cycle/getCycleNoLimit',
      getCageDetail: 'cage/getCageDetail'
    })
  }
}
</script>
