<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table
        style="table-layout:fixed"
        class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider uk-table-middle"
      >
        <thead class="thead-table-paranje">
          <tr>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Tanggal </span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Tahapan</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Pakan (sak)</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Pakan (kg)</span>
            </th>
            <th
              class="table-header uk-table-expand"
              rowspan="2"
            >
              <span class="uk-text-bold">Ayam Mati (ekor)</span>
            </th>
            <th
              class="table-header uk-table-expand"
              rowspan="2"
            >
              <span class="uk-text-bold">Culling (ekor)</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Panen (ekor)</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Sisa Ayam</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">Bobot Sampel (gr)</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">Daily Intake (gr)</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">Feed Intake (gr)</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">FCR</span>
            </th>
            <th
              style="width:240px"
              class="table-header uk-table-expand uk-text-center"
              colspan="3"
            >
              <span class="uk-text-bold">Cum Dep</span>
            </th>
            <th
              style="width:135px"
              class="table-header uk-text-center uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Indeks Prestasi</span>
            </th>
            <!-- <th class="table-header uk-width-small uk-text-center" rowspan="2"><span class="uk-text-bold">Aksi</span></th> -->
          </tr>
          <tr>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Ekor
            </td>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody
            v-if="dataCycle.length>0"
            style="border: 1px solid #e5e5e5;"
          >
            <tr
              v-for="(item, i) in dataCycle"
              :key="i"
              style="background: #fff;"
            >
              <td>{{ item.workdate || '-' }}</td>
              <td>{{ item.activity_name || '-' }}</td>
              <td>
                <input
                  v-model="item.pakan_sak"
                  class="uk-input"
                  type="text"
                  @change="setData('pakan_sak', item.pakan_sak, i)"
                >
              </td>
              <td>{{ item.group_pakan_actual || 0 }}</td>
              <td>
                <input
                  v-model="item.group_deplesi_died"
                  class="uk-input"
                  type="text"
                  @change="setData('group_deplesi_died', item.group_deplesi_died, i)"
                >
              </td>
              <td>
                <input
                  v-model="item.group_deplesi_culling"
                  class="uk-input"
                  type="text"
                  @change="setData('group_deplesi_culling', item.group_deplesi_culling, i)"
                >
              </td>
              <td>
                <input
                  v-model="item.harvest"
                  class="uk-input"
                  type="text"
                  disabled
                  @change="setData('harvest', item.harvest, i)"
                >
              </td>
              <td>{{ item.chic_left || 0 }}</td>
              <td class="act-column uk-text-center">
                <input
                  v-model="item.group_body_weight_actual"
                  class="uk-input"
                  type="text"
                  @change="setData('group_body_weight_actual', item.group_body_weight_actual, i)"
                >
              </td>
              <td class="std-column uk-text-center">
                {{ item.group_body_weight_standard || 0 }}
              </td>
              <td class="act-column uk-text-center">
                {{ setDecimal(item.group_daily_intake_actual) || 0 }}
              </td>
              <td class="std-column uk-text-center">
                {{ item.group_daily_intake_standard || 0 }}
              </td>
              <td class="act-column uk-text-center">
                {{ setDecimal(item.group_feed_intake_actual) || 0 }}
              </td>
              <td class="std-column uk-text-center">
                {{ item.group_feed_intake_standard || 0 }}
              </td>
              <td class="act-column uk-text-center">
                {{ setDecimal(item.group_fcr_actual) || 0 }}
              </td>
              <td class="std-column uk-text-center">
                {{ setDecimal(item.group_fcr_standard) || 0 }}
              </td>
              <td class="act-column uk-text-center">
                {{ setDecimal(item.group_cum_dep_actual) || 0 }}
              </td>
              <td class="std-column uk-text-center">
                {{ setDecimal(item.group_cum_dep_standard) || 0 }}
              </td>
              <td class="act-column uk-text-center">
                {{ setDecimal(item.group_cum_dep_left) || 0 }}
              </td>
              <td class="act-column uk-text-center">
                {{ setDecimal(item.ip_actual) || 0 }}
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="21"
          />
        </template>
        <template v-else>
          <loading-table :colspan="21" />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString, float3Decimals, float1Decimals } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    EmptyTable,
    LoadingTable
  },
  props: {
    saveData: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      status: STATUS,
      images: PREFIX_IMAGE,
      dataCycle: []
    }
  },
  computed : {
    ...mapGetters({
      is_loading: 'cycle/cycles_loading',
      cycles: 'cycle/cycles_no_limit'
    })
  },
  watch: {
    cycles () {
      const newCycle = this.cycles ? (
        this.cycles.cycle.length > 7 ? this.cycles.cycle.slice(this.cycles.cycle.length - 7, this.cycles.cycle.length) : this.cycles.cycle
      ) : []
      const newFlag = this.cycles ? (
        this.cycles.cycle_done_input.length > 7 ? this.cycles.cycle_done_input.slice(this.cycles.cycle_done_input.length - 7, this.cycles.cycle_done_input.length) : this.cycles.cycle_done_input
      ) : []
      let newData = {}
      newCycle.map((item, i) => {
        newData = {
          id: item.id,
          log_cycle_id: item.log_cycle_id,
          log_activity_id: item.log_activity_id,
          workdate: dateUtcParanjeString(item.workdate),
          activity_name: item.activity_name,
          pakan_sak: item.pakan_sak != 0 ? this.set1Decimal(item.pakan_sak) : this.cekFlag(newFlag[i].pakan_sak_is_done),
          group_pakan_actual: item.group_pakan_actual != 0 ? item.group_pakan_actual : 0,
          group_deplesi_died: item.group_deplesi_died != 0 ? item.group_deplesi_died : this.cekFlag(newFlag[i].group_deplesi_died_is_done),
          group_deplesi_culling: item.group_deplesi_culling != 0 ? item.group_deplesi_culling : this.cekFlag(newFlag[i].group_deplesi_culling_is_done),
          harvest: 0,
          chic_left: item.chic_left != 0 ? item.chic_left : 0,
          group_body_weight_actual: item.group_body_weight_actual != 0 ? item.group_body_weight_actual : this.cekFlag(newFlag[i].group_body_weight_actual_is_done),
          group_body_weight_standard: item.group_body_weight_standard != 0 ? item.group_body_weight_standard : 0,
          group_daily_intake_actual: item.group_daily_intake_actual != 0 ? item.group_daily_intake_actual : 0,
          group_daily_intake_standard: item.group_daily_intake_standard != 0 ? item.group_daily_intake_standard : 0,
          group_feed_intake_actual: item.group_feed_intake_actual != 0 ? item.group_feed_intake_actual : 0,
          group_feed_intake_standard: item.group_feed_intake_standard != 0 ? item.group_feed_intake_standard : 0,
          group_fcr_actual: item.group_fcr_actual != 0 ? item.group_fcr_actual : 0,
          group_fcr_standard: item.group_fcr_standard != 0 ? item.group_fcr_standard : 0,
          group_cum_dep_actual: item.group_cum_dep_actual != 0 ? item.group_cum_dep_actual : 0,
          group_cum_dep_standard: item.group_cum_dep_standard != 0 ? item.group_cum_dep_standard : 0,
          group_cum_dep_left: item.group_cum_dep_left != 0 ? item.group_cum_dep_left : 0,
          ip_actual: item.ip_actual != 0 ? item.ip_actual : 0
        }
        this.dataCycle.push(newData)
      })
    }
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    formatFloat(number) {
      return float3Decimals(number)
    },
    ...mapActions({
    }),
    ...mapMutations({
    }),
    setDecimal (data) {
      return float3Decimals(data)
    },
    set1Decimal (number) {
      return float1Decimals(number)
    },
    cekFlag (data) {
      const newData = data == 1 ? 0 : null
      return newData
    },
    setData(value, data, i) {
      switch (value) {
      case 'pakan_sak':
        // eslint-disable-next-line no-case-declarations
        const newPakan = float1Decimals(data)
        this.dataCycle[i].pakan_sak = data == null ? null : newPakan
        this.dataCycle[i].group_pakan_actual = parseInt(newPakan * 50)
        this.dataCycle[i].pakan_sak_is_edited = newPakan ? true : false
        break
      case 'group_deplesi_died':
        this.dataCycle[i].group_deplesi_died = data == null ? null : parseInt(data)
        this.dataCycle[i].group_deplesi_died_is_edited = data ? true : false
        break
      case 'group_deplesi_culling':
        this.dataCycle[i].group_deplesi_culling = data == null ? null : parseInt(data)
        this.dataCycle[i].group_deplesi_culling_is_edited = data ? true : false
        break
      case 'harvest':
        this.dataCycle[i].harvest = parseInt(data)
        break
      case 'group_body_weight_actual':
        this.dataCycle[i].group_body_weight_actual = data == null ? null : parseInt(data)
        this.dataCycle[i].group_body_weight_actual_is_edited = data ? true : false
        break
      default:
        break
      }
      this.saveData(this.dataCycle)
    }
  }
}
</script>

<style scoped>
.uk-input {
  height: 37px !important;
  padding: 0px 10px;
}
.table-header {
  vertical-align: inherit;
  min-width: 125px !important;
  width: 125px;
}
.uk-text-center {
  border-left: 1px solid #B0DFCC;
  border-right: 1px solid #B0DFCC;
}
.act-std {
  padding: 0;
  height: 24px;
}
.act-column {
  border-right: none;
  width: 50px;
}
.std-column {
  border-left: none;
  background: #F0F5F3;
  width: 50px;
}
</style>
